import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { DisplayHotelsComponent } from './views/display-hotels/display-hotels.component';
import { FulfilmentScreenComponent } from './views/fulfilment-screen/fulfilment-screen.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'fulfilment-screen',
    component: FulfilmentScreenComponent,
    data: {
      title: 'Fulfilment Update Screen Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: 'hotels',
    component: DisplayHotelsComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Display Hotel'
    }
  },
  {
    path: 'pos',
    // component: PosComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Pos'
    },
    loadChildren: () =>
      import('./views/pos/pos.module').then((m) => m.PosModule)
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          )
      },
      {
        path: 'products',
        loadChildren: () =>
          import('./views/products/products.module').then(
            (m) => m.ProductsModule
          )
      },
      {
        path: 'cash-register',
        loadChildren: () =>
          import('./views/cash-register/cash-register.module').then(
            (m) => m.CashRegisterModule
          )
      },
      {
        path: 'sales',
        loadChildren: () =>
          import('./views/sales/sales.module').then((m) => m.SalesModule)
      },
      {
        path: 'purchases',
        loadChildren: () =>
          import('./views/purchases/purchases.module').then(
            (m) => m.PurchasesModule
          )
      },
      {
        path: 'stock-transfer',
        loadChildren: () =>
          import('./views/stock-transfer/stock-transfer.module').then(
            (m) => m.StockTransferModule
          )
      },
      {
        path: 'stock-adjustment',
        loadChildren: () =>
          import('./views/stock-adjustment/stock-adjustment.module').then(
            (m) => m.StockAdjustmentModule
          )
      },
      {
        path: 'stock-audit',
        loadChildren: () =>
          import('./views/stock-audit/stock-audit.module').then(
            (m) => m.StockAuditModule
          )
      },
      {
        path: 'mfg',
        loadChildren: () =>
          import('./views/mfg/mfg.module').then((m) => m.MfgModule)
      },
      {
        path: 'fulfilments',
        loadChildren: () =>
          import('./views/fulfilments/fulfilments.module').then(
            (m) => m.FulfilmentsModule
          )
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./views/reports/reports.module').then((m) => m.ReportsModule)
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./views/settings/settings.module').then(
            (m) => m.SettingsModule
          )
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./views/user/user.module').then((m) => m.UserModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
