import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SupplierModel } from '../../models/supplier.model';
import { CategoryModel } from '../../models/category.model';
import { HotelModel } from '../../models/hotel.model';
import { BusinessLocationModel } from '../../models/business-location.model';
import { SubCategoryModel } from '../../models/sub-category.model';
import { UnitModel } from '../../models/unit.model';
import { MenuModel } from '../../models/menu.model';
import { ProductModel } from '../../models/product.model';
import { SalesModel } from '../../models/sales.model';
import { BASEURL } from '../constants/base-url';
import { EmployeeModel } from '../../models/employee.model';
import { PurchaseModel } from '../../models/purchase.model';
import { ProductHistoryModel } from '../../models/product-history.model';
import { StockTransferModel } from '../../models/stock-transfer.model';
import { StockAdjustmentModel } from '../../models/stock-adjustment.model';
import { StockAuditModel } from '../../models/stock-audit.model';
import { CashRegisterModel } from '../../models/cash-register.model';
import { RoomModel } from '../../models/room.model';
import { OrderTableRoomModel } from '../../models/order-table-room.model';
import { OrderModel } from '../../models/order.model';
import { PaymentModel } from '../../models/payment.model';
import { TableRoomSalesModel } from '../../models/table-room-sales.model';
import { ProductSalesReportModel } from '../../models/product-sales-report.model';
import { AccountDetailModel } from '../../models/account-detail.model';
import { SummaryModel } from '../../models/summary.model';
import { ManagerCreditModel } from '../../models/manager-credit.model';
import { UserRoleModel } from '../../models/user-role.model';
import { MfgProduction } from '../../models/mfg-production.model';
import {
  Mfg,
  MfgProductItem,
  MfgProduct
} from '../../models/mfg-product.model';
import { ReportAnalysisModel } from '../../models/stock-analysis.model';
import {
  FulfilmentProductDetails,
  FulfilmentProductReportModel
} from '../../models/fulfilment-report-model';
import { VipDataModel } from '../../models/vip-data.model';
import { LoginModel } from '../../models/login.model';
import { TaxPaymentModel } from '../../models/tax-payment.model';

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private baseUrl = BASEURL;
  constructor(private http: HttpClient) {}

  createSupplier(path, supplierDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      supplierDetails
    );
  }

  getSuppliers(path) {
    return this.http.get<SupplierModel[]>(`${this.baseUrl}/${path}`);
  }

  updateSupplier(path, newSupplier) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      newSupplier
    );
  }

  deleteSupplier(path) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  createCategory(path, categoryDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      categoryDetails
    );
  }

  getCategories(path, useCache = true) {
    return this.http.get<CategoryModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  updateCategory(path, newCategory) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      newCategory
    );
  }

  deleteCategory(path) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  updateSubCategory(path, newSubCategory) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      newSubCategory
    );
  }

  deleteSubCategory(path) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  createSubCategory(path, subCatDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      subCatDetails
    );
  }

  getSubCategories(path, useCache = true) {
    return this.http.get<SubCategoryModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getHotels(path) {
    return this.http.get<HotelModel[]>(`${this.baseUrl}/${path}`);
  }

  createBusinessLocation(path, businessLocation) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      businessLocation
    );
  }

  editBusinessLocation(path, newBusinessLocation) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      newBusinessLocation
    );
  }

  getBusinessLocations(path) {
    return this.http.get<BusinessLocationModel[]>(`${this.baseUrl}/${path}`);
  }

  deleteBusinessLocation(path) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  createUnit(path, unitDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, unitDetails);
  }

  updateUnit(path, unitDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, unitDetails);
  }

  getUnits(path, useCache = true) {
    return this.http.get<UnitModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  deleteUnit(path) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  createMenu(path, menuDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, menuDetails);
  }

  updateMenu(path, menuDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, menuDetails);
  }

  getMenus(path, useCache = true) {
    return this.http.get<MenuModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getMenu(path) {
    return this.http.get<MenuModel>(`${this.baseUrl}/${path}`);
  }

  deleteMenu(path) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  createProduct(path, productDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      productDetails
    );
  }

  updateProduct(path, productDetails) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      productDetails
    );
  }

  deleteProduct(path) {
    return this.http.delete<{ message: string }>(`${this.baseUrl}/${path}`);
  }

  getProducts(path, useCache = true) {
    return this.http.get<ProductModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getMappedProductDetails(path) {
    return this.http.get<ProductModel[]>(`${this.baseUrl}/${path}`);
  }
  getProductDetails(path) {
    return this.http.get<ProductModel>(`${this.baseUrl}/${path}`);
  }

  getMfgProductDetails(path) {
    return this.http.get<MfgProduct>(`${this.baseUrl}/${path}`);
  }

  getMfgProductDetails2(path) {
    return this.http.get<MfgProduct[]>(`${this.baseUrl}/${path}`);
  }

  getMfgProduct(path) {
    return this.http.get<Mfg>(`${this.baseUrl}/${path}`);
  }

  getMfgProductionDetails(path) {
    return this.http.get<MfgProduction>(`${this.baseUrl}/${path}`);
  }

  getUniqueProductName(path) {
    return this.http.get<ProductModel>(`${this.baseUrl}/${path}`);
  }

  createSales(path, salesDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      salesDetails
    );
  }

  getSales(path) {
    return this.http.get<SalesModel[]>(`${this.baseUrl}/${path}`);
  }

  getEmployees(path, useCache = true) {
    return this.http.get<EmployeeModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getPurchases(path, useCache = true) {
    return this.http.get<PurchaseModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getProductions(path, useCache = true) {
    return this.http.get<MfgProduction[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  acceptProductions(path, data) {
    return this.http.post(`${this.baseUrl}/${path}`, data);
  }

  getProductionItems(path, useCache = true) {
    return this.http.get<MfgProduct>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getPurchase(path) {
    return this.http.get<PurchaseModel>(`${this.baseUrl}/${path}`);
  }
  getSale(path) {
    return this.http.get<SalesModel>(`${this.baseUrl}/${path}`);
  }

  getTransfers(path, useCache = true) {
    return this.http.get<StockTransferModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getTransfer(path) {
    return this.http.get<StockTransferModel>(`${this.baseUrl}/${path}`);
  }

  getProductHistory(path, useCache = true) {
    return this.http.get<ProductHistoryModel[]>(`${this.baseUrl}/${path}`, {
      headers: { usecache: `${useCache}` }
    });
  }

  getAdjustments(path) {
    return this.http.get<StockAdjustmentModel[]>(`${this.baseUrl}/${path}`);
  }

  getAudits(path) {
    return this.http.get<StockAuditModel[]>(`${this.baseUrl}/${path}`);
  }
  getAudit(path) {
    return this.http.get<StockAuditModel>(`${this.baseUrl}/${path}`);
  }

  getCashRegisters(path, usecache = true) {
    return this.http.get<CashRegisterModel[]>(`${this.baseUrl}/${path}`, {
      headers: {
        usecache: `${usecache}`
      }
    });
  }

  getCashRegister(path) {
    return this.http.get<CashRegisterModel>(`${this.baseUrl}/${path}`);
  }

  getRooms(path) {
    return this.http.get<RoomModel[]>(`${this.baseUrl}/${path}`);
  }

  getOrderTablesRooms(path) {
    return this.http.get<OrderTableRoomModel[]>(`${this.baseUrl}/${path}`);
  }

  getOrderTableRoom(path) {
    return this.http.get<OrderTableRoomModel>(`${this.baseUrl}/${path}`);
  }

  getOrders(path) {
    return this.http.get<OrderModel[]>(`${this.baseUrl}/${path}`);
  }

  getOrder(path) {
    return this.http.get<OrderModel>(`${this.baseUrl}/${path}`);
  }

  getPayments(path) {
    return this.http.get<PaymentModel[]>(`${this.baseUrl}/${path}`);
  }

  getTableRoomSalesReport(path) {
    return this.http.get<TableRoomSalesModel[]>(`${this.baseUrl}/${path}`);
  }

  getProductSalesReport(path) {
    return this.http.get<ProductSalesReportModel[]>(`${this.baseUrl}/${path}`);
  }

  getUserRoles(path) {
    return this.http.get<UserRoleModel[]>(`${this.baseUrl}/${path}`);
  }

  getUserRole(path) {
    return this.http.get<UserRoleModel>(`${this.baseUrl}/${path}`);
  }

  getSummary(path) {
    return this.http.get<SummaryModel[]>(`${this.baseUrl}/${path}`);
  }

  processProductFile(path, data) {
    return this.http.post<ProductModel[]>(`${this.baseUrl}/${path}`, data);
  }

  getSku(path) {
    return this.http.get<{ message: string; sku: string }>(
      `${this.baseUrl}/${path}`
    );
  }
  getPOS(path) {
    return this.http.get<AccountDetailModel[]>(`${this.baseUrl}/${path}`);
  }

  handleMcCode(path, data) {
    return this.http.post<{ managerCode: string }>(
      `${this.baseUrl}/${path}`,
      data
    );
  }

  changeWaterCode(path: string, employeeDetail) {
    return this.http.put<EmployeeModel>(
      `${this.baseUrl}/${path}`,
      employeeDetail
    );
  }
  getManagerCreditList(path: string) {
    return this.http.get<ManagerCreditModel[]>(`${this.baseUrl}/${path}`);
  }

  updateConfirmationStatus(path: string, updateDetail) {
    return this.http.put(`${this.baseUrl}/${path}`, updateDetail);
  }

  helperPost(path, data) {
    return this.http.post<{
      webError: string;
      success: string;
      message: string;
      errorProducts: string;
    }>(`${this.baseUrl}/${path}`, data);
  }

  helperPut(path, data) {
    return this.http.put<{ message?: string }>(`${this.baseUrl}/${path}`, data);
  }

  helperDelete(path) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  createManufacturing(path, mfgDetails) {
    return this.http.post<{ message: string }>(
      `${this.baseUrl}/${path}`,
      mfgDetails
    );
  }

  editManufacturing(path, mfgDetails) {
    return this.http.put<{ message: string }>(
      `${this.baseUrl}/${path}`,
      mfgDetails
    );
  }

  deleteProduction(path) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  getMenuPerformance(path) {
    return this.http.get<MenuModel[]>(`${this.baseUrl}/${path}`);
  }

  getProductPerformance(path) {
    return this.http.get<ProductModel[]>(`${this.baseUrl}/${path}`);
  }

  getReportAnalysis(path) {
    return this.http.get<ReportAnalysisModel[]>(`${this.baseUrl}/${path}`);
  }

  getFulfilments(path) {
    return this.http.get<FulfilmentProductReportModel[]>(
      `${this.baseUrl}/${path}`
    );
  }

  getAllVIPs(path) {
    return this.http.get<VipDataModel[]>(`${this.baseUrl}/${path}`);
  }

  editVIP(path, vip) {
    return this.http.put<{ message: string }>(`${this.baseUrl}/${path}`, vip);
  }

  getFulfilmentDetails(path) {
    return this.http.get<FulfilmentProductDetails[]>(`${this.baseUrl}/${path}`);
  }

  getLogAuditTrail(path: string) {
    return this.http.get<LoginModel[]>(`${this.baseUrl}/${path}`);
  }

  createMajorCategorySettings(path, majorCatDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, majorCatDetails);
  }

  createSubCategorySettings(path, subCatDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, subCatDetails);
  }

  createMiniCategorySettings(path, miniCatDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, miniCatDetails);
  }

  getMajorCategorySettings(path) {
    return this.http.get<MenuModel>(`${this.baseUrl}/${path}`);
  }

  getSubCategorySettings(path) {
    return this.http.get<MenuModel>(`${this.baseUrl}/${path}`);
  }
  getSub_Mini_CategorySettings(path) {
    return this.http.get<MenuModel>(`${this.baseUrl}/${path}`);
  }
  getMiniCategorySettings(path) {
    return this.http.get<MenuModel>(`${this.baseUrl}/${path}`);
  }

  updateMenuCatSettings(path, menuCatDetails) {
    return this.http.put(`${this.baseUrl}/${path}`, menuCatDetails);
  }

  deleteMenuCatSettings(path) {
    return this.http.delete(`${this.baseUrl}/${path}`);
  }

  createCentralHotelProductMapping(path, centralProductDetails) {
    return this.http.post(`${this.baseUrl}/${path}`, centralProductDetails);
  }


  getSelectedProduct(path) {
    return this.http.get<PurchaseModel>(`${this.baseUrl}/${path}`);
  }

  getBusinessCustomer(path: string){
    return this.http.get<HotelModel[]>(`${this.baseUrl}/${path}`);
  }
  createBusinessCustomer(path, data){
    return this.http.post<{message: string}>(`${this.baseUrl}/${path}`, data);
  }

  // getMappedProducts(path) {
  //   return this.http.get<ProductModel>(`${this.baseUrl}/${path}`);
  // }
  getTaxLevies(path: string) {
    return this.http.get<TaxPaymentModel[]>(`${this.baseUrl}/${path}`);
  }
}
