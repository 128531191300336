

import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { OrderModel } from '../../models/order.model';
import { PageErrorModel } from '../../models/page-error.model';
import { ProductModel } from '../../models/product.model';
import { HttpService } from '../../shared/services/http.service';
import { FunctionsService } from '../../shared/services/functions.service';
import { AsyncFunctionsService } from '../../shared/services/async-functions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { timer } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-fulfilment-screen',
  templateUrl: './fulfilment-screen.component.html',
  styleUrls: ['./fulfilment-screen.component.scss']
})
export class FulfilmentScreenComponent implements OnInit {
  // fulfilmentLists: OrderModel = [];
  @ViewChild('printDocketBtn') printDocketBtn: ElementRef<HTMLElement>;
  fulfilmentLists: OrderModel[] = [];
  AllFulfilmentLists: OrderModel[] = [];
  totalRecords;
  page = 1;
  loading = true;
  docketLoading = true;
  hotelId = this.functions.getCurrentHotelId();
  employeeId = this.functions.getLoggedInUserId();
  pageError: PageErrorModel = new PageErrorModel();
  modalRef;
  modalRef2;
  showAlert = false;
  alertMessage;
  alertType;
  totalOrders;
  products: ProductModel[] = [];
  docketPrintItem;
  // currentOrderDetail: OrderModel = new OrderModel();
  orderDetails: ProductModel[] = [];
  currentOrderDetail: OrderModel = new OrderModel();
  processing: boolean;
  currentEmployee;
  currentHotel;
  fulfilStatus = 'all';
  timerObs = timer(0, 60000);
  timerSub;
  currentTime;

  constructor(
    private httpService: HttpService,
    private functions: FunctionsService,
    private asyncFunctions: AsyncFunctionsService,
    private modalService: NgbModal,
    private store: Store<AppState>
  ) {}

  async ngOnInit(): Promise<void> {
    this.getFulfillmentLists();
    this.getCurrentEmployee();

    this.timerSub = this.timerObs.subscribe(() => {
      this.currentTime = moment().format('Do MMMM YYYY, h:mm:ss a');
      this.getFulfillmentLists();
    });
  }

  getCurrentEmployee() {
    this.store.select('hotels').subscribe((res) => {
      const [employeeData] = res.filter(
        (hotel) => hotel.hotelId === +this.hotelId
      );
      this.currentEmployee = employeeData.fbAccessRole;
      this.currentHotel = employeeData.hotelName;
      // console.log('this.currentEmployee', employeeData);
    });
  }

  getOrderByStatus() {
    this.getFulfillmentLists();
  }

  // mcStatus = 'used';

  getFulfillmentLists() {
    this.loading = true;
    this.httpService
      .getOrders(`fulfilments/${this.hotelId}/getKitchenFilmentOrders`)
      .subscribe(
        (res) => {
          // console.log('this.fulfilmentLists', res);
          if (this.fulfilStatus) {
            if (this.fulfilStatus === 'all') {
              this.fulfilmentLists = res;
            } else {
              this.fulfilmentLists = res.filter(
                (e) => e.orderStatus === this.fulfilStatus
              );
            }
          } else {
            this.fulfilmentLists = res;
          }
          this.AllFulfilmentLists = res;
          this.totalOrders = this.fulfilmentLists?.length;
          for (const prod of this.fulfilmentLists) {
            prod.requestedDateTime = this.functions.extractDateTime(
              prod.requestedDateTime
            );
            prod.requestedBy = this.asyncFunctions.getEmployeeName(
              prod.requestedBy
            );
            prod.requestedBy = this.asyncFunctions.getEmployeeName(
              prod.serviceStaff
            );
          }
          // console.log('this.fulfilmentLists', this.fulfilmentLists);
          this.loading = false;
        },
        (err) => {
          this.displayPageError(err);
        }
      );
  }

  updateOrderStatus(action, orderId, orderProductId) {
    this.processing = true;
    const updateDetails = {
      type: action,
      orderId,
      orderProductId,
      employeeId: this.employeeId
    };

    this.httpService
      .updateConfirmationStatus(
        'fulfilments/kitchenUpdateFulfilment',
        updateDetails
      )
      .subscribe(
        () => {
          this.processing = false;
          this.getFulfillmentLists();
        },
        (err) => {
          console.log(err);
        }
      );
  }

  getOrderByNumber($event) {
    const name = $event.target?.value;
    // this.fulfilmentLists = this.AllFulfilmentLists.filter((e) => e.order?.indexOf(name) > -1);
    this.fulfilmentLists = this.AllFulfilmentLists.filter(
      (e) => e.orderNumber?.indexOf(name) > -1
    );
    this.totalOrders = this.fulfilmentLists?.length;
    for (const prod of this.AllFulfilmentLists) {
      prod.requestedDateTime = this.functions.extractDateTime(
        prod.requestedDateTime
      );
      prod.requestedBy = this.asyncFunctions.getEmployeeName(prod.requestedBy);
    }
    // console.log(name, this.guests);
  }

  fetchOrderDetails(orderId, orderProductId, content) {
    this.openLg(content);
    this.currentOrderDetail = null;
    this.docketLoading = true;
    this.httpService
      .getOrder(`fulfilments/${orderId}/singleOrderRequest`)
      .subscribe(
        (res) => {
          this.products = res.products.filter(
            (e) => e.orderProductId === orderProductId
          );
          this.currentOrderDetail = res;
          this.docketLoading = false;
        },
        (err) => {
          this.modalRef.close();
          this.displayPageError(err);
        }
      );
  }

  displayPageError(err) {
    this.pageError = this.functions.interpretError(err);
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.pageError.code !== 404) {
      console.log(err);
    }
    this.showAlert = true;
    this.alertMessage = this.pageError.message;
    this.alertType = 'danger';
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  open(content: TemplateRef<any>) {
    this.modalRef = this.modalService.open(content);
    this.modalRef.result.then(
      () => {},
      () => {}
    );
  }

  openLg(content: TemplateRef<any>) {
    this.modalRef = this.modalService.open(content, { size: 'lg' });
    this.modalRef.result.then(
      () => {},
      () => {}
    );
  }

  /* printCheckInReceipt() {
    setTimeout(() => {
      this.printExtraPayReceiptBtn.nativeElement.click();
      // console.log(this.abc.nativeElement.innerText);
    }, 1000);

  }*/

  printDocket() {
    // if (this.modalRef) {
    //   this.modalRef.close();
    // }
    this.printDocketBtn.nativeElement.click();
  }
}
