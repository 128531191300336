import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BASEURL } from '../constants/base-url';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import * as EmployeeActions from '../../store/actions/employee.action';
import * as HotelsActions from '../../store/actions/hotels.action';
import * as HotelEmployeesActions from '../../store/actions/hotel-employees.action';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = BASEURL;
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>
  ) {}

  loginUser(path: string, userDetails) {
    return this.http.post<{ token: string; data: any }>(
      `${this.baseUrl}/${path}`,
      userDetails
    );
  }

  loggedIn() {
    return !!localStorage.getItem('x-access-token');
  }

  logOut() {
    localStorage.removeItem('x-access-token');
    localStorage.removeItem('employee');
    localStorage.removeItem('access:hotels');
    localStorage.removeItem('hotels');
    localStorage.removeItem('hotelId');
    localStorage.removeItem('current:hotel');
    this.store.dispatch(new EmployeeActions.logoutEmployee());
    this.store.dispatch(new HotelsActions.clearHotels());
    this.store.dispatch(new HotelEmployeesActions.clearHotelEmployees());
    this.router.navigate(['/login']);
  }

  getAccessToken() {
    return localStorage.getItem('x-access-token');
  }

  authTest(path) {
    return this.http.get(`${this.baseUrl}/${path}`);
  }

  insertLoginDetails(path: string, details) {
    return this.http.post(`${this.baseUrl}/${path}`, details);
  }

  insertLogoutDetails(path: string, details) {
    return this.http.put(`${this.baseUrl}/${path}`, details);
  }
}
