import { ProductModel } from './product.model';

export class OrderTableRoomModel {
  orderTableRoomId: string;
  tableOrRoomId: string;
  hotelId: string;
  type: string;
  registerId: string;
  locationId: string;
  typeOfService: string;
  customer: string;
  numberOfPerson: number;
  serviceStaff: string;
  employeeName: string;
  openedBy: string;
  openedDateTime: string;
  lastOrdered: string;
  totalBill: number;
  totalPaid: number;
  amountPaid: number;
  currentDateTime: string;
  active: boolean | string;
  balance: number;
  closedDateTime: string;
  invoiceNumber: string;
  closedBy: string;
  tableName: string;
  status: string;
  roomNumber: number;
  roomId: number;
  quantity: number;
  hasOrder: boolean;
  invoiceItems: ProductModel[];
  allInvoiceItems: ProductModel[];
  invoiceDetails: unknown;
  invoiceNum: string | number;
  invoiceAmount: any;
  toShow: boolean;
  checkInId: number | string;
  guestId: string | number;
  hasFulfil: boolean;
  products: ProductModel[];
  requestedDateTime: string;
  requestedBy: string;
  orderStatus: string;
  orderStatusList: any;
  orderTaxesRes: any;
  taxLevyAmount: number;
  invoiceTaxes: any;
  userType: string;
  loungeName: string;
}
