import { ProductModel } from './product.model';

export class PurchaseModel {
  purchaseId: string;
  poNumber: string;
  hotelId: string;
  purchaser: string;
  purchaseDate: string;
  purchaseStatus: string;
  purchaseQuantity: number;
  paymentStatus: string;
  receivedBy: string;
  receivedDate: string;
  supplier: string;
  supplierName: string;
  businessLocation: string;
  businessLocationName: string;
  comment: string;
  createdBy: string;
  products: ProductModel[];
  totalSystemPrice: number;
  totalAmount: number;
  newPrice: number;
  createdDateTime: string;
  productName: string;
  quantityReceived: string;
  unitPrice: string;
  currentPrice: string;
  marketPriceTotal: string;
  paidAmount: string;
  lineTotal: string;
  quantityUsed: number;
  alreadyPaid: number;
  purchase: PurchaseModel;
  productCategory: string;
  categoryName: any;
  totalSpent: string | number;
  invoiceNumber: string;
}
