import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import { AsyncFunctionsService } from '../../shared/services/async-functions.service';
import {
  ADMIN_SECTION_BREAKER,
  CASH_REGISTER_NAV,
  CASH_REGISTER_NAV_CHILDREN,
  DASHBOARD_NAV,
  FULFILMENT_NAV,
  FULFILMENT_NAV_CHILDREN,
  MFG_NAV,
  MFG_NAV_CHILDREN,
  PRODUCTS_NAV,
  PRODUCTS_NAV_CHILDREN,
  PURCHASES_NAV,
  PURCHASES_NAV_CHILDREN,
  REPORTS_NAV,
  REPORTS_NAV_CHILDREN,
  SALES_NAV,
  SALES_NAV_CHILDREN,
  SETTINGS_NAV,
  SETTINGS_NAV_CHILDREN,
  STOCK_ADJUSTMENT_NAV,
  STOCK_ADJUSTMENT_NAV_CHILDREN,
  STOCK_AUDIT_NAV,
  STOCK_AUDIT_NAV_CHILDREN,
  STOCK_TRANSFER_NAV,
  STOCK_TRANSFER_NAV_CHILDREN
} from '../../shared/constants/sideNavs';
import { FunctionsService } from '../../shared/services/functions.service';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems;
  hotelName;
  employeeName;
  userAccessRole;
  employeeId;
  currentYear

  constructor(
    private authService: AuthService,
    private asyncFunctions: AsyncFunctionsService,
    private functions: FunctionsService
  ) {}

  async ngOnInit(): Promise<void> {
    this.getDisplayDetails();
    await this.performUserAuthorization();
    this.asyncFunctions.getHotelEmployees();
    this.currentYear = moment().format('YYYY-MM-DD HH:mm:00');
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logOut() {
    const momentDateTime = moment().format('YYYY-MM-DD HH:mm:00');
    const details = {
      employeeId: this.employeeId,
      logoutDateTime: momentDateTime
    };
    this.authService
      .insertLogoutDetails('employees/insertLogout', details)
      .subscribe(
        (res) => {
          this.authService.logOut();
        },
        (err) => {
          console.log(err);
        }
      );
    // this.authService.logOut();
  }

  async composeNavItems() {
    const { permissions } = await this.asyncFunctions.getAccessRoleData();
    const userNavItems = [];
    for (const permission of permissions) {
      const permissionOptions = permission.options.map((e) => e.optionValue);
      switch (permission.permissionValue) {
        case 'dashboard':
          userNavItems.push(DASHBOARD_NAV);
          break;
        case 'products':
          const productNav = { ...PRODUCTS_NAV };
          productNav.children = PRODUCTS_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(productNav);
          break;
        case 'menu':
        case 'unit':
        case 'category':
          const [currentProductsNav] = userNavItems.filter(
            (e) => e.id === 'products'
          );
          const [itemNav] = PRODUCTS_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          if (currentProductsNav) {
            currentProductsNav.children.push(itemNav);
          } else {
            const productNav2 = { ...PRODUCTS_NAV };
            productNav2.children = [itemNav];
            userNavItems.push(productNav2);
          }
          break;
        case 'cash_register':
          const cashRegisterNav = { ...CASH_REGISTER_NAV };
          cashRegisterNav.children = CASH_REGISTER_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(cashRegisterNav);
          break;
        case 'sales':
          const salesNav = { ...SALES_NAV };
          salesNav.children = SALES_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(salesNav);
          break;
        case 'purchases':
          const purchasesNav = { ...PURCHASES_NAV };
          purchasesNav.children = PURCHASES_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(purchasesNav);
          break;
        case 'stock_transfer':
          const stockTransferNav = { ...STOCK_TRANSFER_NAV };
          stockTransferNav.children = STOCK_TRANSFER_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(stockTransferNav);
          break;
        case 'stock_adjustment':
          const stockAdjustmentNav = { ...STOCK_ADJUSTMENT_NAV };
          stockAdjustmentNav.children = STOCK_ADJUSTMENT_NAV_CHILDREN.filter(
            (e) => permissionOptions.includes(e.id)
          );
          userNavItems.push(stockAdjustmentNav);
          break;
        case 'stock_audit':
          const stockAuditNav = { ...STOCK_AUDIT_NAV };
          stockAuditNav.children = STOCK_AUDIT_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(stockAuditNav);
          break;
        case 'mfg':
          const mfgNav = { ...MFG_NAV };
          mfgNav.children = MFG_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(mfgNav);
          break;
        case 'fulfilments':
          const fulfilNav = { ...FULFILMENT_NAV };
          fulfilNav.children = FULFILMENT_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(fulfilNav);
          break;
        case 'reports':
          const reportsNav = { ...REPORTS_NAV };
          reportsNav.children = REPORTS_NAV_CHILDREN.filter((e) =>
            permissionOptions.includes(e.id)
          );
          userNavItems.push(reportsNav);
          break;
        case 'admin_section':
          const defaultChildren = ['business_locations', 'product_files', 'business_customers'];
          const adminNav = { ...SETTINGS_NAV };
          adminNav.children = SETTINGS_NAV_CHILDREN.filter((e) =>
            defaultChildren.includes(e.id)
          );
          userNavItems.push(ADMIN_SECTION_BREAKER);
          userNavItems.push(adminNav);
          break;
        case 'roles':
        case 'contacts':
          const [adminSection] = userNavItems.filter(
            (e) => e.id === 'settings'
          );
          if (adminSection) {
            const [navItem] = SETTINGS_NAV_CHILDREN.filter(
              (e) => e.id === permission.permissionValue
            );
            adminSection.children.push(navItem);
          }
          break;
      }
    }
    return userNavItems;
  }

  async performUserAuthorization() {
    this.navItems = await this.composeNavItems();
  }

  getDisplayDetails() {
    const currentHotel = JSON.parse(localStorage.getItem('current:hotel'));
    this.hotelName = currentHotel?.hotelName;

    const { employeeName, employeeId } = JSON.parse(
      localStorage.getItem('employee')
    );
    this.employeeName = employeeName;
    this.employeeId = employeeId;
  }
}
