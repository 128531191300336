import { BusinessLocationModel } from './business-location.model';
import { HotelModel } from './hotel.model';
import { ProductModel } from './product.model';

export class MenuModel {
  menuId: string;
  menuName: string;
  activeFromDate: string;
  activeFromDateStr: string;
  activeFromTime: string;
  activeFromTimeStr: string;
  activeToTime: string;
  activeToTimeStr: string;
  objActiveFromDate: any;
  objActiveFromTime: any;
  objActiveToTime: any;
  locations: BusinessLocationModel[] | string[];
  currentStatus: string;
  availability: string;
  createdDateTime: string;
  numberOfItems: number;
  createdBy: string;
  hotelId: number;
  isGlobal: string | boolean;
  hotels: HotelModel[];
  products: ProductModel[];
  comboName: string;
  unitSold: string;
  taxAmount: string;
  subTotal: string;
  unitPrice: string;
  lineTotal: string;
  revenue: number;
  price: number;
  quantity: number;
  locationStr: string;
  majorCategoryName: string;
  sortNumber: number;
  miniCategoryName: string;
  subCategoryId: string;
  hotelGroupId: any;
  subCategoryName: string;
  miniCat: [];
  majorCategoryId: string;
  menuMajorLabels: any;

}
