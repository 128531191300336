import { HotelModel } from '../../models/hotel.model';

export const GET_HOTELS = '[HOTELS] get';
export const CLEAR_HOTELS = '[HOTELS] clear';

export class getHotels {
  readonly type = GET_HOTELS;

  constructor(public payload: HotelModel[]) {}
}

export class clearHotels {
  readonly type = CLEAR_HOTELS;
}

export type Actions = getHotels | clearHotels;
