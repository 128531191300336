import * as HotelsActions from '../actions/hotels.action';
import { HotelModel } from '../../models/hotel.model';

const getHotelsInitialState = (): HotelModel[] => {
  return JSON.parse(localStorage.getItem('access:hotels'));
};

const initialState: HotelModel[] = getHotelsInitialState();

export const hotelsReducer = (
  state: HotelModel[] = initialState,
  action: HotelsActions.Actions
): any => {
  switch (action.type) {
    case HotelsActions.GET_HOTELS:
      return action.payload;
    case HotelsActions.CLEAR_HOTELS:
      return null;
    default:
      return state;
  }
};
