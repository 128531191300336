import { Component, OnInit } from '@angular/core';
import { HotelModel } from '../../models/hotel.model';
import { AuthService } from '../../shared/services/auth.service';
import { Router } from '@angular/router';
import { AsyncFunctionsService } from '../../shared/services/async-functions.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { FunctionsService } from '../../shared/services/functions.service';
import { ToastService } from '../../shared/services/toast.service';
import { CacheService } from '../../shared/services/cache.service';
import { HttpService } from '../../shared/services/http.service';

@Component({
  selector: 'app-display-hotels',
  templateUrl: './display-hotels.component.html',
  styleUrls: ['./display-hotels.component.scss']
})
export class DisplayHotelsComponent implements OnInit {
  hotels: HotelModel[] = [];
  hotelsPos: HotelModel[] = [];
  employeeId = this.functions.getLoggedInUserId();
  allHotels: HotelModel[] = [];
  searchKey: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private asyncFunctions: AsyncFunctionsService,
    private functions: FunctionsService,
    private store: Store<AppState>,
    private toastService: ToastService,
    private cacheService: CacheService,
    private httpService: HttpService
  ) {}

  ngOnInit(): void {
    this.hotels = this.asyncFunctions.getEmployeeAccessHotels();
    this.store.select('hotels').subscribe((res) => {
      this.hotels = res;
      if (this.hotels) {
        this.hotelsPos = [...this.hotels];
      }
      this.allHotels = res;
    });
  }

  proceedToDashboard(hotel) {
    if (!hotel.fbAccessRole) {
      this.showToast(
        'Error!',
        'You need an access role to access this location. Please ask admin to assign an access role to you.'
      );
    } else {
      localStorage.setItem('current:hotel', JSON.stringify(hotel));
      this.updateLoginDetails(hotel);
      this.router.navigate(['/dashboard']);
    }
  }

  showToast(header, message) {
    this.toastService.show(message, header, {
      classname: 'bg-danger text-light',
      delay: 10000
    });
  }

  proceedToPos(hotel, disabled) {
    if (!disabled) {
      const [currentHotel] = this.hotels.filter(
        (e) => e.hotelId === hotel.hotelId
      );
      if (this.cacheService.currentPOS.hotel !== currentHotel.hotelId) {
        this.cacheService.currentPOS.hotel = currentHotel.hotelId;
        this.cacheService.currentPOS.location = 0;
      }
      localStorage.setItem('current:hotel', JSON.stringify(currentHotel));
      this.router.navigate(['/pos/dashboard']);
      this.updateLoginDetails(hotel);
    }
  }

  handleHotelFilter($event) {
    const searchKey = $event.target.value;
    console.log('searchKey', this.searchKey);
    let filterHotel;
    filterHotel = this.allHotels.filter((e) => e.hotelName.toLowerCase()?.indexOf(searchKey.toLowerCase()) > -1);
    this.hotels = filterHotel;
    this.hotelsPos = [...filterHotel];
  }

  updateLoginDetails(hotel) {
    const details = {
      employeeId: this.employeeId,
      hotelId: hotel.hotelId
    };
    this.httpService.helperPut(`employees/updateLogin`, details).subscribe(
      () => {},
      (err) => {
        console.log(err);
      }
    );
  }

  logOut() {
    this.authService.logOut();
  }
}
