import { PaymentModel } from './payment.model';
import { StockRegisterModel } from './stock-register.model';

export class CashRegisterModel {
  registerId: string;
  locationId: string;
  employeeId: string;
  employeeName: string;
  businessLocationName: string;
  hasOpen: boolean;
  active: string | boolean;
  openedDateTime: string;
  amountSold: number;
  openingCash: number;
  closingCash: number;
  totalTransactions: number;
  totalCash: number;
  confirmed: boolean | string;
  payments: PaymentModel[];
  stocks: StockRegisterModel[];
  closedBy: string;
  closedDateTime: string;
  closingSummary: string;
  confirmationNote: string;
  confirmationStatus: string;
}
