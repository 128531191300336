import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  updateProduct = {
    success: false,
    message: ''
  };
  updateFieldItem = {
    success: false,
    message: ''
  };
  closeRegisterSuccess = false;
  currentProductTab = 0;
  currentRegisterTab = 0;
  currentOptionTab = 'personal';
  currentPOS = {
    hotel: undefined,
    location: 0,
    menu: undefined
  };
}

