import { ProductModel } from './product.model';

export class SalesModel {
  saleId: string;
  hotelId: string;
  saleDate: string;
  customerType: string;
  paymentMethod: string;
  totalAmount: number;
  amountPaid: number;
  totalItems: number;
  createdBy: string;
  createdDateTime: string;
  statuss: string;
  locationId: string;
  businessLocationName: string;
  sellingPriceGroup: string;
  quantity: number;
  serviceType: string;
  paymentNote: string;
  formattedDate: any;
  unitPrice: number;
  lineTotal: number;
  sales: string;
  productName: string;
  products: ProductModel[];
  poNumber: string;
  purchaser: string;
  supplier: string;
  ivNumber: string;
  selectedHotelId: any;
}
