import { Injectable, OnInit } from '@angular/core';
import { HttpService } from './http.service';
import { HotelModel } from '../../models/hotel.model';
import { ConstantsService } from './constants.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import * as HotelsActions from '../../store/actions/hotels.action';
import { FunctionsService } from './functions.service';
import * as HotelEmployeesActions from '../../store/actions/hotel-employees.action';
import { EmployeeModel } from '../../models/employee.model';
import { UserRoleModel } from '../../models/user-role.model';
import { ALL_PERMISSION } from '../constants/permissions';
import { ModulePermissionModel } from '../../models/module-permission.model';

@Injectable({
  providedIn: 'root'
})
export class AsyncFunctionsService implements OnInit {
  bgColors = this.constantsService.colors;
  hotelId = this.functions.getCurrentHotelId();
  hotelEmployees: EmployeeModel[] = [];

  constructor(
    private httpService: HttpService,
    private constantsService: ConstantsService,
    private store: Store<AppState>,
    private functions: FunctionsService
  ) {}

  ngOnInit() {}

  getEmployeeAccessHotels(): HotelModel[] {
    const storedHotels = localStorage.getItem('access:hotels');
    if (storedHotels) {
      return JSON.parse(storedHotels);
    } else {
      this.httpService
        .getHotels(`employees/hotels?withAccessRole=true`)
        .subscribe(
          (res) => {
            for (const hotel of res) {
              hotel.borderColor = this.bgColors[
                Math.floor(Math.random() * this.bgColors.length)
              ];
            }
            this.store.dispatch(new HotelsActions.getHotels(res));
            localStorage.setItem('access:hotels', JSON.stringify(res));
            return res;
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  getHotelEmployees() {
    this.httpService.getEmployees(`employees/${this.hotelId}`, false).subscribe(
      (res) => {
        this.hotelEmployees = res;
        this.store.dispatch(new HotelEmployeesActions.getHotelEmployees(res));
      },
      (err) => {
        console.log(err);
      }
    );
  }

  getEmployeeName(employeeId) {
    const [employee] = this.hotelEmployees?.filter(
      (e) => e.employeeId === employeeId
    );
    return employee?.employeeName;
  }

  sendError(err) {
    throw new Error(err);
  }

  async getAccessRoleData(): Promise<UserRoleModel> {
    try {
      const currentHotel = JSON.parse(localStorage.getItem('current:hotel'));
      const roleId = currentHotel.fbAccessRole?.roleId;
      if (roleId) {
        const url = `roles/role/${roleId}`;
        return await this.httpService.getUserRole(url).toPromise();
      }
      this.sendError('RoleId does not exist. Access role misbehaving');
    } catch (err) {
      this.sendError(err);
    }
  }

  async getAccessRoleForModule(moduleName): Promise<ModulePermissionModel> {
    try {
      const accessRoles = await this.getAccessRoleData();
      const [userModulePermission] = accessRoles.permissions.filter(
        (e) => e.permissionValue === moduleName
      );
      const [allModulePermission] = ALL_PERMISSION.filter(
        (e) => e.value === moduleName
      );

      const userPermissionsArr =
        userModulePermission?.options.map((e) => e.optionValue) || [];
      return allModulePermission.options.reduce((obj, option) => {
        obj[
          this.functions.convertWordToCamelCase(option.value)
        ] = userPermissionsArr.includes(option.value);
        return obj;
      }, {});
    } catch (err) {
      throw new Error(err);
    }
  }
}
