// export const BASEURL = 'http://197.210.166.58:3007/api/v1';
// export const BASEURL = 'http://newfb.intellipms.com:3004/api/v1';
export const BASEURL = 'https://ipmsfb.intellims.com:5400/app/v1';
// // export const BASEURL = 'http://fb.intellipms.com:3005/api/v1';
// export const BASEURL = 'http://10.0.5.51:3018/api/v1';
// import { environment } from '../../../environments/environment';
//
// export const BASEURL = environment.apiUrl;






