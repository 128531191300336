import { ProductModel } from './product.model';

export class StockAuditModel {
  auditId: string;
  auditNumber: string;
  hotelId: string;
  businessLocation: string;
  businessLocationName: string;
  comment: string;
  createdBy: string;
  createdDateTime: string;
  products: ProductModel[];
  numberOfProducts: string;
  status: string;
}
