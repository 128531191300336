import { EmployeeModel } from '../../models/employee.model';

export const LOGIN_EMPLOYEE = '[EMPLOYEE] login';
export const LOGOUT_EMPLOYEE = '[EMPLOYEE] logout';

export class loginEmployee {
  readonly type = LOGIN_EMPLOYEE;

  constructor(public payload: EmployeeModel) {}
}

export class logoutEmployee {
  readonly type = LOGOUT_EMPLOYEE;
}

export type Actions = loginEmployee | logoutEmployee;
