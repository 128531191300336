import * as HotelEmployeesActions from '../actions/hotel-employees.action';
import { EmployeeModel } from '../../models/employee.model';

const initialState: EmployeeModel[] = [];

export const hotelEmployeeReducer = (
  state: EmployeeModel[] = initialState,
  action: HotelEmployeesActions.Actions
): any => {
  switch (action.type) {
    case HotelEmployeesActions.GET_HOTEL_EMPLOYEES:
      return action.payload;
    case HotelEmployeesActions.CLEAR_HOTEL_EMPLOYEES:
      return null;
    default:
      return state;
  }
};
