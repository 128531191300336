import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { ToastsContainer } from './toasts/toasts.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { PrintPageComponent } from './print-page/print-page.component';

@NgModule({
  declarations: [
    MultiSelectComponent,
    AutocompleteComponent,
    ToastsContainer,
    PrintPageComponent
  ],
  exports: [
    MultiSelectComponent,
    AutocompleteComponent,
    ToastsContainer,
    PrintPageComponent
  ],
  imports: [CommonModule, NgSelectModule, FormsModule, NgbToastModule]
})
export class ComponentsModule {}
