import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {
  data = [];
  @Input() dropdownList = [];
  @Output() inputModelChange = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  public onSelectAll() {
    const selected = this.dropdownList.map((item) => item.id);
    this.data = [];
    this.data.push(...selected);
  }

  public onClearAll() {
    this.data = [];
  }
}
