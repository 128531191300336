export const ALL_PERMISSION = [
  {
    name: 'Dashboard',
    value: 'dashboard',
    options: [
      {
        name: 'View Dashboard',
        value: 'view_dashboard',
        checked: false
      }
    ]
  },
  {
    name: 'Products',
    value: 'products',
    options: [
      {
        name: 'View Products',
        value: 'view_products',
        checked: false
      },
      {
        name: 'Add Products',
        value: 'add_products',
        checked: false
      },
      {
        name: 'Edit Products',
        value: 'edit_products',
        checked: false
      },
      {
        name: 'Product Mapping',
        value: 'product_invoice',
        checked: false
      },
      {
        name: 'Delete Products',
        value: 'delete_products',
        checked: false
      }
    ]
  },
  {
    name: 'Menu',
    value: 'menu',
    options: [
      {
        name: 'View Menu',
        value: 'view_menu',
        checked: false
      },
      {
        name: 'Add Menu',
        value: 'add_menu',
        checked: false
      },
      {
        name: 'Edit Menu',
        value: 'edit_menu',
        checked: false
      },
      {
        name: 'Delete Menu',
        value: 'delete_menu',
        checked: false
      }
    ]
  },
  {
    name: 'Unit',
    value: 'unit',
    options: [
      {
        name: 'View Unit',
        value: 'view_unit',
        checked: false
      },
      {
        name: 'Add Unit',
        value: 'add_unit',
        checked: false
      },
      {
        name: 'Edit Unit',
        value: 'edit_unit',
        checked: false
      },
      {
        name: 'Delete Unit',
        value: 'delete_unit',
        checked: false
      }
    ]
  },
  {
    name: 'Category',
    value: 'category',
    options: [
      {
        name: 'View Category',
        value: 'view_category',
        checked: false
      },
      {
        name: 'Add Category',
        value: 'add_category',
        checked: false
      },
      {
        name: 'Edit Category',
        value: 'edit_category',
        checked: false
      },
      {
        name: 'Delete Category',
        value: 'delete_category',
        checked: false
      }
    ]
  },
  {
    name: 'Cash Register',
    value: 'cash_register',
    options: [
      {
        name: 'View Cash Register',
        value: 'view_cash_register',
        checked: false
      },
      {
        name: 'Open Cash Register',
        value: 'open_cash_register',
        checked: false
      },
      {
        name: 'Close Cash Register',
        value: 'close_cash_register',
        checked: false
      },
      {
        name: 'View Cash Register Audit',
        value: 'view_cash_register_audit',
        checked: false
      }
    ]
  },
  {
    name: 'Direct Sales',
    value: 'sales',
    options: [
      {
        name: 'View Sales',
        value: 'view_sales',
        checked: false
      },
      {
        name: 'Add Sales',
        value: 'add_sales',
        checked: false
      },
      {
        name: 'Used Stocks',
        value: 'used-stock',
        checked: false
      },
      {
        name: 'Edit Sales',
        value: 'edit_sales',
        checked: false
      },
      {
        name: 'Delete Sales',
        value: 'delete_sales',
        checked: false
      }
    ]
  },
  {
    name: 'Customer Direct Sales',
    value: 'customer_sales',
    options: [
      {
        name: 'Open Table',
        value: 'open_table',
        checked: false
      },
      {
        name: 'Close Table',
        value: 'close_table',
        checked: false
      },
      {
        name: 'Place Order',
        value: 'place_order',
        checked: false
      },
      {
        name: 'Take Payment',
        value: 'take_payment',
        checked: false
      }
    ]
  },
  {
    name: 'Purchases',
    value: 'purchases',
    options: [
      {
        name: 'View Purchases',
        value: 'view_purchases',
        checked: false
      },
      {
        name: 'Add Purchases',
        value: 'add_purchases',
        checked: false
      },
      {
        name: 'Edit Purchases',
        value: 'edit_purchases',
        checked: false
      },
      {
        name: 'Purchase Action',
        value: 'purchase_action',
        checked: false
      },
      {
        name: 'Payment Action',
        value: 'payment_action',
        checked: false
      }
    ]
  },
  {
    name: 'Stock Transfer',
    value: 'stock_transfer',
    options: [
      {
        name: 'View Stock Transfer',
        value: 'view_stock_transfer',
        checked: false
      },
      {
        name: 'Add Stock Transfer',
        value: 'add_stock_transfer',
        checked: false
      },
      {
        name: 'Edit Stock Transfer',
        value: 'edit_stock_transfer',
        checked: false
      },
      {
        name: 'Delete Stock Transfer',
        value: 'delete_stock_transfer',
        checked: false
      },
      {
        name: 'Transfer Action',
        value: 'transfer_action',
        checked: false
      }
    ]
  },
  {
    name: 'Stock Adjustment',
    value: 'stock_adjustment',
    options: [
      {
        name: 'View Stock Adjustment',
        value: 'view_stock_adjustment',
        checked: false
      },
      {
        name: 'Add Stock Adjustment',
        value: 'add_stock_adjustment',
        checked: false
      },
      {
        name: 'Edit Stock Adjustment',
        value: 'edit_stock_adjustment',
        checked: false
      },
      {
        name: 'Delete Stock Adjustment',
        value: 'delete_stock_adjustment',
        checked: false
      }
    ]
  },
  {
    name: 'Stock Audit',
    value: 'stock_audit',
    options: [
      {
        name: 'View Stock Audit',
        value: 'view_stock_audit',
        checked: false
      },
      {
        name: 'Add Stock Audit',
        value: 'add_stock_audit',
        checked: false
      },
      {
        name: 'Edit Stock Audit',
        value: 'edit_stock_audit',
        checked: false
      },
      {
        name: 'Delete Stock Audit',
        value: 'delete_stock_audit',
        checked: false
      }
    ]
  },
  {
    name: 'MFG',
    value: 'mfg',
    options: [
      {
        name: 'Add MFG',
        value: 'add_mfg',
        checked: false
      },
      {
        name: 'View MFG',
        value: 'view_mfg',
        checked: false
      }
    ]
  },
  {
    name: 'Fulfilments',
    value: 'fulfilments',
    options: [
      {
        name: 'Fulfilment List',
        value: 'fulfilment_lists',
        checked: false
      }
    ]
  },
  {
    name: 'Reports',
    value: 'reports',
    options: [
      {
        name: 'Products Report',
        value: 'products_report',
        checked: false
      },
      {
        name: 'Locations Report',
        value: 'locations_report',
        checked: false
      },
      {
        name: 'Customer Sales Report',
        value: 'customer_sales_report',
        checked: false
      },
      {
        name: 'Direct Sales Report',
        value: 'direct_sales_report',
        checked: false
      },
      {
        name: 'Purchase Report',
        value: 'purchase_report',
        checked: false
      },
      {
        name: 'Management Report',
        value: 'management_report',
        checked: false
      }
    ]
  },
  {
    name: 'Admin Section',
    value: 'admin_section',
    options: [
      {
        name: 'Access Admin Section',
        value: 'access_admin_section',
        checked: false
      }
    ]
  },
  {
    name: 'Roles',
    value: 'roles',
    options: [
      {
        name: 'View Roles',
        value: 'view_roles',
        checked: false
      },
      {
        name: 'Add Roles',
        value: 'add_roles',
        checked: false
      },
      {
        name: 'Edit Roles',
        value: 'edit_roles',
        checked: false
      },
      {
        name: 'Delete Roles',
        value: 'delete_roles',
        checked: false
      }
    ]
  },
  {
    name: 'Contacts',
    value: 'contacts',
    options: [
      {
        name: 'View Contacts',
        value: 'view_contacts',
        checked: false
      },
      {
        name: 'Add Contacts',
        value: 'add_contacts',
        checked: false
      },
      {
        name: 'Edit Contacts',
        value: 'edit_contacts',
        checked: false
      },
      {
        name: 'Delete Contacts',
        value: 'delete_contacts',
        checked: false
      }
    ]
  }
];
