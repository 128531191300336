import { MetaReducer, ActionReducerMap, ActionReducer } from '@ngrx/store';
import { AppState } from './state/app.state';
import { employeeReducer } from './reducers/employee.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { hotelsReducer } from './reducers/hotels.reducer';
import { hotelEmployeeReducer } from './reducers/hotel-employees.reducer';

export const employeeMetaReducer = (
  reducer: ActionReducer<AppState>
): ActionReducer<AppState> => {
  return localStorageSync({ keys: ['employee', 'hotels'] })(reducer);
};

export const reducers: ActionReducerMap<AppState> = {
  employee: employeeReducer,
  hotels: hotelsReducer,
  hotelEmployees: hotelEmployeeReducer
};

export const metaReducers: MetaReducer[] = [employeeMetaReducer];
