import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  colors = [
    'bg-teal',
    'bg-pink',
    'bg-purple',
    'bg-blue',
    'bg-behance',
    'bg-green',
    'bg-yellow',
    'bg-cyan',
    'bg-orange',
    'bg-pink',
    'bg-indigo',
    'bg-vimeo',
    'bg-spotify',
    'bg-reddit',
    'bg-flickr',
    'bg-facebook',
    'bg-xing',
    'bg-github',
    'bg-youtube',
    'bg-yahoo',
    'bg-vine'
  ];
}
