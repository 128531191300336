import { EmployeeModel } from '../../models/employee.model';

export const GET_HOTEL_EMPLOYEES = '[HOTELEMPLOYEES] get';
export const CLEAR_HOTEL_EMPLOYEES = '[HOTELEMPLOYEES] clear';

export class getHotelEmployees {
  readonly type = GET_HOTEL_EMPLOYEES;

  constructor(public payload: EmployeeModel[]) {}
}

export class clearHotelEmployees {
  readonly type = CLEAR_HOTEL_EMPLOYEES;
}

export type Actions = getHotelEmployees | clearHotelEmployees;
