import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { FunctionsService } from '../../services/functions.service';
import { AutocompleteModel } from '../../../models/autocomplete.model';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent implements OnInit {
  @Input() value = '';
  @Input() placeholder = '';
  @Input() exclude = [];
  @Input() location: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() productType: 'single' | 'combo' | 'manufacturing' = 'single';
  @Input() useProducts = [];
  @Output() valueChange = new EventEmitter();
  @Output() listClicked = new EventEmitter();

  data: AutocompleteModel[] = [];
  showSuggestion = false;
  hotelId = this.functions.getCurrentHotelId();
  constructor(
    private httpService: HttpService,
    private functions: FunctionsService,
    private eRef: ElementRef
  ) {}

  ngOnInit(): void {}

  handleFilter($event) {
    this.value = $event.target.value;
    this.showSuggestion = !!this.value;
    let url;
    if (this.type === 'manufacturing') {
      url = `products/${this.hotelId}?fetchType=simple&productType=${this.type}&productName=${this.value}`;
    } else if (this.location) {
      url = `products/${this.hotelId}?fetchType=simple&productType=${this.productType}&productName=${this.value}&location=${this.location}`;
    } else {
      url = `products/${this.hotelId}?fetchType=simple&productType=${this.productType}&productName=${this.value}`;
    }
    this.httpService.getProducts(url, false).subscribe(
      (res) => {
        this.organizeData(res);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  organizeData(res) {
    if (this.type === 'manufacturing') {
      this.productType = 'manufacturing';
    }
    if (this.productType === 'single') {
      const excludeData = this.exclude.map((e) => e.productId);
      const dataToSuggest = res.filter(
        (e) => !excludeData.includes(e.productId)
      );
      this.data = dataToSuggest.map((e) => ({
        id: e.productId,
        name: e.productName,
        price: e.purchasePrice
      }));
    } else if (this.productType === 'combo') {
      const excludeData = this.exclude.map((e) => e.comboId);
      const useData = this.useProducts.map((e) => e.comboId);
      let dataToSuggest;
      if (useData.length > 0) {
        dataToSuggest = res.filter((e) => useData.includes(e.comboId));
      } else {
        dataToSuggest = res.filter((e) => !excludeData.includes(e.comboId));
      }
      this.data = dataToSuggest.map((e) => ({
        id: e.comboId,
        name: e.comboName,
        price: e.price
      }));
    } else if (this.productType === 'manufacturing') {
      this.data = res.map((e) => ({
        id: e.mfgId,
        name: e.generalName
      }));
    }
  }

  handleListClicked(value) {
    this.value = value.name;
    this.showSuggestion = false;
    this.listClicked.emit(value);
    if (this.productType === 'combo' || 'manufacturing') {
      this.value = '';
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showSuggestion = false;
    }
  }
}
